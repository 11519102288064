<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-tabs align="left">
            <b-tab active title="Data Historis"> <historis /></b-tab>
            <b-tab title="Data Raw" lazy><raw /></b-tab>
            <!-- <b-tab title="Maps" no-body lazy><maps /></b-tab> -->
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Raw from "./tabledataraw.vue";
import Historis from "./tabledatahistoris.vue";

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    raw: Raw,
    historis: Historis,
    //chart: Chart,
  },
};
</script>
